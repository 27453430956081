import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'hmt-enhanced-datagrid-datetime-renderer',
  templateUrl: './enhanced-datagrid-datetime-renderer.component.html',
  styleUrls: ['./enhanced-datagrid-datetime-renderer.component.scss'],
})
export class EnhancedDatagridDatetimeRendererComponent<TData = Record<string, unknown>>
  implements ICellRendererAngularComp
{
  private params!: ICellRendererParams<TData, Date>;
  value!: Date;

  agInit(params: ICellRendererParams<TData, Date>): void {
    this.params = params;
    this.setValue(params);
  }

  refresh(params: ICellRendererParams<TData, Date>): boolean {
    this.params = params;
    this.setValue(params);
    return true;
  }

  private setValue(params: ICellRendererParams<TData, Date>) {
    this.value = params.value;
  }
}
