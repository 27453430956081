import { Component } from '@angular/core';

@Component({
  selector: 'hmt-requirement-overview-port-pair-card',
  templateUrl: './requirement-overview-port-pair-card.component.html',
  styleUrl: './requirement-overview-port-pair-card.component.scss'
})
export class RequirementOverviewPortPairCardComponent {

}
