import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow, parseISO } from 'date-fns';

@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
  transform(value: string | Date): string {
    if (!value) {
      return '';
    }
    try {
      return formatDistanceToNow(typeof value === 'string' ? parseISO(value) : value, { addSuffix: true });
    } catch (error) {
      console.error(error);
      return 'less than a minute ago';
    }
  }
}
