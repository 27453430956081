import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LocationCountryDetails } from '@shared/models/org-country-details.model';
import { LocationsService } from '@shared/services/locations.service';
import { take } from 'rxjs';

@Component({
  selector: 'hmt-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit {
  locationsService = inject(LocationsService);

  @Input() formGroup: FormGroup;
  @Output() addContact = new EventEmitter();
  @Output() removeContact = new EventEmitter<number>();

  filteredCountriesList: LocationCountryDetails[] = [];
  countries: LocationCountryDetails[] = [];

  ngOnInit(): void {
    this.locationsService
      .getCountriesAndStates()
      .pipe(take(1))
      .subscribe((response: LocationCountryDetails[]) => {
        this.countries = response;
      });
  }

  onSelectCountry(): void {
    this.filteredCountriesList = [];
  }

  searchCountry(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const e = target.value;
    // this.formValidity.set(false);
    const val = e.toLowerCase();
    this.filteredCountriesList = this.countries.filter(country => {
      // INFO: HERE -1 is used to check if the value is not found, so no need to use a constant
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (country.countryName.toLowerCase().indexOf(val) !== -1 || !val) {
        return country;
      } else {
        return false;
      }
    });
  }

  onClickCountry() {
    if (!this.formGroup.get(['contactDetails', 'country']).value) {
      this.filteredCountriesList = this.countries;
    }
  }

  deleteContact(val): void {
    this.removeContact.emit(val);
  }

  addNewContact() {
    this.addContact.emit();
  }
}
