<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="image-attachment-wizard-container">
  <div class="title">{{ title }}</div>
  <div class="preview">
    @if (attachment?.imageUrl) {
      <img class="image" [src]="attachment.imageUrl" alt="Attachment" />
    }
  </div>
  <button mat-flat-button color="primary" class="attach-button" (click)="openFileExplorer()">
    <label>
      {{ getButtonName() }}
    </label>
    <mat-icon aria-hidden="false">attach_file</mat-icon>
    <input id="file-input" class="file-input" #imageInput type="file" (change)="processFile(imageInput)" />
  </button>
  <div class="messages" *ngFor="let message of attachment?.message">
    <span class="message">{{ message }}</span>
  </div>
</div>
