import { Component, computed, input } from '@angular/core';
import { PrincipalContact } from '@shared/models/principal-contact.model';

@Component({
  selector: 'hmt-contact-view',
  templateUrl: './contact-view.component.html',
  styleUrls: ['./contact-view.component.scss'],
})
export class ContactViewComponent {
  contact = input<PrincipalContact>(null);

  fullName = computed(() => {
    const firstName = this.contact()?.firstName;
    const lastName = this.contact()?.lastName;
    if (!firstName || (firstName.trim() === '' && !lastName) || lastName.trim() === '') {
      return 'N/A';
    }
    return `${firstName} ${lastName}`;
  });

  mobile = computed(() => {
    const mobile = this.contact()?.mobile;
    if (!mobile || mobile.trim() === '') {
      return 'N/A';
    }
    return mobile;
  });

  address = computed(() => {
    const rawAddress = `${this.contact()?.officeAddress?.addressLine ?? ''} ${this.contact()?.officeAddress?.city ?? ''}`;
    if (!rawAddress || rawAddress.trim() === '') {
      return 'N/A';
    }
    return rawAddress;
  });

  phone = computed(() => {
    const phone = this.contact()?.officeContact?.phoneNumber;
    if (!phone || phone.trim() === '') {
      return 'N/A';
    }
    return phone;
  });

  ext = computed(() => {
    const ext = this.contact()?.officeContact?.ext;
    if (!ext || ext.trim() === '') {
      return 'N/A';
    }
    return ext;
  });

  fax = computed(() => {
    const fax = this.contact()?.faxNumber;
    if (!fax || fax?.trim() === '') {
      return 'N/A';
    }
    return fax;
  });

  email = computed(() => {
    const email = this.contact()?.email;
    if (!email || email.trim() === '') {
      return 'N/A';
    }
    return email;
  });
}
