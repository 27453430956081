/* eslint-disable @angular-eslint/no-input-rename */
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { MultiSelectEntry } from '@shared/models/multi-select-entry.model';

@Component({
  selector: 'hmt-multi-select-drop-down',
  templateUrl: './multi-select-drop-down.component.html',
  styleUrls: ['./multi-select-drop-down.component.scss'],
})
export class MultiSelectDropDownComponent {

  @Input() placeholder: string;
  @Input() label: string;

  @Input('selectedItems') set setSelectedItems(res: MultiSelectEntry[]) {
    if (res) {
      this.selectedItems = res;
    }
  }


  @Input('itemList') set setItem(res: MultiSelectEntry[]) {
    if (res) {
      this.itemList = res;
      this.setFilteredItems();
    }
  }

  @Input('formControl') set setFormControl(formControl: FormControl) {
    this.formControl = formControl;
  }

  @Output() selectedItemListEvent = new EventEmitter<MultiSelectEntry[]>();

  @ViewChild('selectedItemInput') selectedItemInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  selectedItems: MultiSelectEntry [] = [];
  itemList: MultiSelectEntry[];
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredItems: Observable<MultiSelectEntry[]>;
  formControl = new FormControl();

  constructor() {

  }

  setFilteredItems(): void {
    this.filteredItems = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => this.filterAutoSearch(value || '')));
  }

  remove(item: MultiSelectEntry): void {
    const index = this.selectedItems.indexOf(item);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);

      this.formControl.setValue('');
      this.selectedItemListEvent.emit(this.selectedItems);
    }
  }

  selectItem(event: MultiSelectEntry): void {
    this.selectedItems.push(event);
    this.selectedItemInput.nativeElement.value = '';
    this.formControl.setValue('');
    this.selectedItemListEvent.emit(this.selectedItems);
  }

  filterAutoSearch(value: string): MultiSelectEntry[] {
    const items = this.itemList.filter(item => {
      if (this.selectedItems.filter(selectedItem => selectedItem.id === item.id).length === 0) {
        return item;
      }
      return false;
    });
    if (typeof value !== 'string') {
      return items;
    }
    return items.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
  }
}
