import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTING } from '@configs/app-routing';
import { SetCurrentOperation, SetOperations } from '@core/store/app/app.actions';
import { AppState } from '@core/store/app/app.state';
import { Logout } from '@core/store/auth/auth.actions';
import { AuthState } from '@core/store/auth/auth.state';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { Subject, take, takeUntil, tap } from 'rxjs';
import { FetchNotificationCount, ToggleNotificationDrawer } from '../notification-drawer/state/notification.actions';
import { NotificationState } from '../notification-drawer/state/notification.state';

@Component({
  selector: 'app-header',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  store = inject(Store);
  route = inject(Router);
  actions$ = inject(Actions);
  operations$ = this.store.select(AppState.getOperations);
  currentOperation$ = this.store.select(AppState.getCurrentOperationIds);
  warRoomMessageCount$ = this.store.select(NotificationState.warRoomTotal);
  notificationsCount$ = this.store.select(NotificationState.unreadNotificationsCount);
  userFullName$ = this.store.select(AuthState.getUserFullName);

  selectedOperation: { _id: string; name: string } | { _id: ''; name: 'All' } = { _id: '', name: 'All' };
  operations: ({ _id: string; name: string } | { _id: ''; name: 'All' })[] = [];

  ngOnInit(): void {
    this.store.dispatch(new SetOperations());
    this.store.dispatch(new FetchNotificationCount());
    this.operations$
      .pipe(
        tap(operationsList => {
          this.operations = [
            { _id: '', name: 'All' },
            ...operationsList.map(operation => ({
              _id: operation._id,
              name: operation.name,
            })),
          ];

          this.selectedOperation = this.operations[0];
          this.store.dispatch(new SetCurrentOperation(this.selectedOperation));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.actions$
      .pipe(
        ofActionCompleted(Logout),
        take(1),
        tap(t => {
          this.route.navigate([APP_ROUTING.auth.login], { queryParams: { redirect: t.action.currentPage } });
        })
      )
      .subscribe();
  }

  onOperationChange(selectedOperation: { _id: string; name: string } | { _id: ''; name: 'All' }): void {
    this.selectedOperation = selectedOperation;
    this.store.dispatch(new SetCurrentOperation(this.selectedOperation));
  }

  goToDashboard(): void {
    this.route.navigate(['/dashboard']);
  }

  toggleNotifications() {
    if (!this.route.url.includes('dashboard')) {
      this.store.dispatch(new ToggleNotificationDrawer());
    }
  }

  logout() {
    this.store.dispatch(new Logout(false));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
