import { SubscriptionChannels } from '../enums/subscription-channels.enum';

export const NOTIFICATION_ACTION_PREFIX = '[Notification State]';

export class FetchChannelGroupedNotifications {
  static readonly type = `${NOTIFICATION_ACTION_PREFIX} Fetch Channel Grouped Notifications`;
  constructor(
    public userId: string,
    public limitPerChannel: number,
    public offset: number
  ) {}
}

export class FetchNotificationsByChannel {
  static readonly type = `${NOTIFICATION_ACTION_PREFIX} Fetch Notifications By Channel`;
  constructor(
    public userId: string,
    public channel: SubscriptionChannels,
    public offset: number,
    public limit: number,
    public searchTerm?: string
  ) {}
}

export class ToggleNotificationDrawer {
  static readonly type = `${NOTIFICATION_ACTION_PREFIX} Toggle Notification Drawer`;
}

export class FetchNotificationCount {
  static readonly type = `${NOTIFICATION_ACTION_PREFIX} Fetch Notification Count`;
}

export class MarkNotificationAsRead {
  static readonly type = `${NOTIFICATION_ACTION_PREFIX} Mark Notification As Read`;
  constructor(public notificationId: string) {}
}

export class ResetNotificationState {
  static readonly type = `${NOTIFICATION_ACTION_PREFIX} Reset Notification State`;
}
