import { LineString } from './line-string.model';
import { Polygon } from './polygon.model';

export interface GeoFence {
  coordinates?: number[][][];
  radius?: number;
  type: string;
}

export function isGeoFence(arg: Polygon | LineString | GeoFence): arg is GeoFence {
  return (arg as GeoFence).radius !== undefined;
}
