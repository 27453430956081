// TODO: REPLACE MAGIC NUMBER WITH A NAMED CONSTANT
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@core/models/user.model';
import { AuthState } from '@core/store/auth/auth.state';
import { Select } from '@ngxs/store';
import { EntityMap } from '@shared/types/entity-map.type';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Pipe({
  name: 'authorize',
})
export class AuthorizePipe implements PipeTransform {
  currentUser: User;
  @Select(AuthState.getUser) user$: Observable<User>;

  constructor() {
    this.user$.pipe(tap(user => (this.currentUser = user))).subscribe();
  }

  transform(btnId: string): unknown {
    const currentUserRoles: string[] = this.currentUser.scopes;
    const steps: EntityMap<string, string[]> = {
      js_download_btn: ['fill:wizard'],
      js_confirm_btn: ['approve:job-file'],
      fuel_create_and_approve_btn: ['create:fuel-request', 'approve:fuel-request'],
      fuel_create_btn: ['create:fuel-request'],
      fuel_save_and_approve_btn: ['create:fuel-request'],
      fuel_approve: ['approve:fuel-request'],
      fuel_move_to_pending_btn: ['update:fuel-request'],
      fuel_mark_as_complete_btn: ['update:fuel-request'],
      fuel_approve_btn: ['approve:fuel-request'],
      fuel_reject_btn: ['reject:fuel-request'],
      quotation_create: ['create:quotation'],
      quotation_edit: ['update:quotation'],
      quotation_accept: ['accept:quotation'],
      contract_view: ['view:contract'],
      create_fuel_request_btn: ['create:fuel-request'],
      quotation_download_and_email: ['download:quotation'],
      create_org_and_admin_super: ['create:org', 'admin:super'],
    };
    let ifExist;

    if (btnId.indexOf('and') > -1) {
      ifExist = steps[btnId].every(v => currentUserRoles.includes(v));
    } else {
      ifExist = steps[btnId].some(roles => currentUserRoles.includes(roles));
    }

    return ifExist;
  }
}
