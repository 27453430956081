// TODO: SEE IF THIS CAN BE REMOVED AND PROPERLY IMPLEMENTED
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, EventEmitter, Input, Output } from '@angular/core';
// TODO: FIX THIS IMPORT
// import {Jpm} from '@control-tower/models/jpm';
// TODO: FIX THIS IMPORT
// import { JobFile } from '@core/models/job-file';
import { UtilityService } from '@shared/services/utility.service';
// TODO: FIX THIS IMPORT
// import {ContainerSummary} from '@control-tower/models';

@Component({
  selector: 'hmt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  isGenerateInvoice = true;
  jobRefId: string;

  // TODO: UNCOMMENT AND FIX THIS
  // @Input('jpms') set setJpms(jpms: Jpm[]) {
  //   if (jpms && jpms.length > 0) {
  //     this.jobRefId = jpms[0]?.jobRefId;
  //     this.checkInvoiceGenerate(jpms);
  //   }
  // }

  @Input() isDisabled = false;
  @Input() isReadOnly = false;

  // TODO: UNCOMMENT ALL OF THE FOLLOWING AND FIX THESE
  // @Input() jobFile: JobFile;
  // confirmedContainers: ContainerSummary[] = [];

  // @Input('confirmedContainers') set getConfirmedContainers(containers: ContainerSummary[]){
  //   this.confirmedContainers = containers;
  // }

  @Output() containerTerminate = new EventEmitter();
  @Output() invoiceGenerate: EventEmitter<string> = new EventEmitter();
  @Output() containerDuplicate = new EventEmitter<string>();
  // TODO: UNCOMMENT AND FIX THIS
  // @Output() viewDocuments = new EventEmitter<{ containerSummary: ContainerSummary[]; isReadOnly: boolean }>();
  @Output() terminateJob = new EventEmitter();
  @Output() openUpdateInvoiceInfo = new EventEmitter();

  constructor(public utilService: UtilityService) {}

  terminateContainer(): void {
    this.containerTerminate.emit();
  }

  // TODO: UNCOMMENT AND FIX THIS
  // duplicateContainer(): void {
  //   this.containerDuplicate.emit(this.jobFile?.workflowId);
  // }

  generateInvoice(): void {
    this.invoiceGenerate.emit(this.jobRefId);
  }

  // TODO: UNCOMMENT AND FIX THIS
  // checkInvoiceGenerate(jpms: Jpm[]): void {
  //   if (jpms.every(jpm => jpm.isCompleted)) {
  //     this.isGenerateInvoice = false;
  //   }
  // }

  // TODO: UNCOMMENT AND FIX THIS
  // onViewDocumentsClick(): void {
  //   const result = {
  //     containerSummary: this.confirmedContainers,
  //     isReadOnly: this.isReadOnly,
  //   };
  //   this.viewDocuments.emit(result);
  // }

  onTerminateJobClick(): void {
    this.terminateJob.emit();
  }

  onUpdateInvoiceInfo(): void {
    this.openUpdateInvoiceInfo.emit();
  }
}
