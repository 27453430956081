/* eslint-disable @angular-eslint/no-input-rename */
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  ATTACH_IMAGE,
  FILE_MAX_SIZE_EXCEED,
  IMAGE_DIMENSION_VALIDATION,
  MINIMUM_IMAGE_HEIGHT,
  MINIMUM_IMAGE_WIDTH,
  RE_ATTACH_IMAGE,
  UPLOAD_FILE_MAXIMUM_SIZE,
} from '@configs/constants';
import { FileTypeExtension } from '@shared/enums/file-type-extension.enum';
import { PreSignedUrl } from '@shared/models/pre-signed-url.model';
@Component({
  selector: 'hmt-image-attachment-wizard',
  templateUrl: './image-attachment-wizard.component.html',
  styleUrls: ['./image-attachment-wizard.component.scss'],
})
export class ImageAttachmentWizardComponent {
  attachment: { file?: File; message: string[]; imageUrl: string };
  @ViewChild('imageInput', { read: ElementRef, static: false }) fileInput: ElementRef;

  @Input() title: string;
  @Input('logo') set setLogo(file: PreSignedUrl) {
    this.attachment = {
      imageUrl: file?.url,
      message: [],
    };
  }
  @Output() uploadImage: EventEmitter<File> = new EventEmitter<File>();

  constructor() {}

  processFile(file) {
    const selectedFile = file.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', ev => {
      this.createAttachment(selectedFile, ev);
    });
    reader.readAsDataURL(selectedFile);
  }

  getValidationMessages(file: File, image?: HTMLImageElement): string[] {
    const messages: string[] = [];
    if (image) {
      const dimensionMessage = this.checkImageDimensions(image.height, image.width);
      messages.push(...(dimensionMessage ? [dimensionMessage] : []));
    }
    messages.push(...(file.size > UPLOAD_FILE_MAXIMUM_SIZE ? [FILE_MAX_SIZE_EXCEED] : []));
    return messages;
  }

  createAttachment(selectedFile: File, event): void {
    let image: HTMLImageElement;
    switch (selectedFile.type) {
      case FileTypeExtension.IMAGE_PNG:
      case FileTypeExtension.IMAGE_JPEG:
        image = new Image();
        image.src = event.target.result as string;
        image.onload = () => {
          this.attachment = this.createAttachmentObject(selectedFile, image.src, image);
          this.uploadImage.emit(this.attachment.file);
        };
        break;
      case FileTypeExtension.APPLICATION_MSWORD:
      case FileTypeExtension.APPLICATION_WORD:
      case FileTypeExtension.APPLICATION_DOX:
        this.attachment = this.createAttachmentObject(selectedFile, './assets/icons/doc-icon.svg');
        this.uploadImage.emit(this.attachment.file);
        break;
      case FileTypeExtension.APPLICATION_PDF:
        this.attachment = this.createAttachmentObject(selectedFile, './assets/icons/pdf-icon.svg');
        this.uploadImage.emit(this.attachment.file);
        break;
      default:
        this.attachment = this.createAttachmentObject(selectedFile, './assets/icons/no-image.svg');
        this.uploadImage.emit(this.attachment.file);
        break;
    }
  }

  checkImageDimensions(height: number, width: number): string {
    return height > MINIMUM_IMAGE_HEIGHT && width > MINIMUM_IMAGE_WIDTH ? '' : IMAGE_DIMENSION_VALIDATION;
  }

  createAttachmentObject(
    selectedFile: File,
    imageUrl: string,
    image?: HTMLImageElement
  ): { file: File; message: string[]; imageUrl: string } {
    return {
      file: selectedFile,
      imageUrl,
      message: image ? this.getValidationMessages(selectedFile, image) : this.getValidationMessages(selectedFile),
    };
  }

  getButtonName(): string {
    if (this.attachment?.file) {
      return RE_ATTACH_IMAGE;
    }
    return ATTACH_IMAGE;
  }

  openFileExplorer() {
    this.fileInput.nativeElement.click();
  }
}
