@if (preSignedUrl() && preSignedUrl()?.contentType === fileTypeExtension.APPLICATION_PDF) {
  <ngx-doc-viewer
    class="document-viewer-pdf-doc"
    [url]="preSignedUrl()?.url"
    viewer="url"
    style="height: 100vh; width: 100%"></ngx-doc-viewer>
} @else if (
  preSignedUrl() &&
  (preSignedUrl()?.contentType === fileTypeExtension.IMAGE_JPEG ||
    preSignedUrl()?.contentType === fileTypeExtension.IMAGE_PNG ||
    preSignedUrl()?.contentType === fileTypeExtension.IMAGE_JPG)
) {
  <div class="document-viewer-png-jpeg-container">
    <img class="document-viewer-png-jpeg" src="{{ preSignedUrl()?.url }}" [alt]="file().fileName" />
  </div>
} @else if (preSignedUrl()) {
  <div class="document-viewer-unsupported-container">
    <p>File type not supported yet. Please download to view.</p>
  </div>
} @else {
  <div class="document-viewer-loading-container">
    <mat-spinner></mat-spinner>
  </div>
}

<!-- @else if (
  preSignedUrl() &&
  (preSignedUrl()?.contentType === fileTypeExtension.APPLICATION_OCTET_STREAM ||
    preSignedUrl()?.contentType === fileTypeExtension.APPLICATION_MSWORD ||
    preSignedUrl()?.contentType === fileTypeExtension.APPLICATION_WORD ||
    preSignedUrl()?.contentType === fileTypeExtension.APPLICATION_DOX)
)  -->
