<div class="contact-view-container">
  <div class="column-1">
    <div class="title">
      PRINCIPLE CONTACT
      <div class="divider"></div>
    </div>
    <div class="row-1">
      <div class="name property-title">
        Name: <span class="property-value name-value">{{ fullName() }}</span>
      </div>
      <div class="mobile property-title">
        Mobile: <span class="property-value mobile-value">{{ mobile() }}</span>
      </div>
    </div>
  </div>
  <div class="column-2">
    <div class="title">
      CONTACT DETAILS
      <div class="divider"></div>
    </div>
    <div class="row-1">
      <div class="address property-title">
        Address:
        <div class="address-content property-value">
          {{ address() }}
        </div>
      </div>
      <div class="contact-details">
        <div class="phone property-title">
          Phone:
          <span class="property-value phone-value"
            >{{ phone() }}
            <span class="property-title"> EXT: {{ ext() }}</span>
          </span>
        </div>
        <div class="fax property-title">
          Fax: <span class="property-value fax-value">{{ fax() }}</span>
        </div>
        <div class="email property-title">
          Email: <span class="property-value email-value">{{ email() }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
