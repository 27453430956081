import { gql } from 'apollo-angular';

// TODO: Use a fragment for the repeated fields
export const GET_CHANNEL_GROUPED_NOTIFICATIONS = gql`
  query GetChannelGroupedNotifications($userId: String!, $limitPerChannel: Int, $offset: Int) {
    getChannelGroupedNotifications(userId: $userId, limitPerChannel: $limitPerChannel, offset: $offset) {
      HIGH_PRIORITY {
        items {
          _id
          deliveryChannel
          url
          titleTemplate
          bodyTemplate
          titleTemplateMetaData
          bodyTemplateMetaData
          createdAt
          isRead
        }
        total
      }
      OTHER {
        items {
          _id
          deliveryChannel
          url
          titleTemplate
          bodyTemplate
          titleTemplateMetaData
          bodyTemplateMetaData
          createdAt
          isRead
        }
        total
      }
      JOB_RELATED {
        items {
          _id
          deliveryChannel
          url
          titleTemplate
          bodyTemplate
          titleTemplateMetaData
          bodyTemplateMetaData
          createdAt
          isRead
        }
        total
      }
    }
  }
`;

export const GET_NOTIFICATIONS_BY_CHANNEL = gql`
  query GetNotificationsByChannel(
    $userId: String!
    $channel: SubscriptionChannels!
    $limit: Int
    $offset: Int
    $searchText: String
  ) {
    getNotificationsByChannel(
      userId: $userId
      channel: $channel
      limit: $limit
      offset: $offset
      searchText: $searchText
    ) {
      items {
        _id
        deliveryChannel
        url
        titleTemplate
        bodyTemplate
        titleTemplateMetaData
        bodyTemplateMetaData
        createdAt
        isRead
      }
      total
    }
  }
`;

export const GET_USER_NOTIFICATION_COUNT = gql`
  query GetUserNotificationCount($userId: String!) {
    getUnreadNotificationCount(userId: $userId) {
      total
      highPriority
      other
      jobRelated
    }
  }
`;

export const MARK_NOTIFICATION_AS_READ = gql`
  mutation MarkNotificationAsRead($userId: String!, $notificationId: String!) {
    markNotificationAsRead(userId: $userId, notificationId: $notificationId)
  }
`;
