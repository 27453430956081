<div class="footer">
  <div class="mid-flex-grow row">
    <button
      mat-stroked-button
      color="warn"
      class="delete-container"
      [disabled]="isDisabled || isReadOnly"
      [class.disabled]="isReadOnly"
      [class.generate-invoice]="!isReadOnly"
      (click)="terminateContainer()">
      <!-- TODO: UNCOMMENT AND FIX THIS -->
      <!-- Terminate {{utilService.getRelevantTitleBasedOnKeyOfLoadType(jobFile?.loadType)}} -->
    </button>

    <!-- TODO: UNCOMMENT AND FIX THIS -->
    <!-- <button mat-stroked-button color="primary" id="view-documents" (click)="onViewDocumentsClick()">View
      Documents
    </button> -->

    <button mat-stroked-button color="warn" id="terminate-job" (click)="onTerminateJobClick()" [disabled]="isReadOnly">
      Terminate job
    </button>
  </div>
  <div class="mid-flex-grow"></div>
  <div>
    <button mat-stroked-button id="invoice" [disabled]="isGenerateInvoice || isReadOnly" (click)="generateInvoice()">
      Generate Invoice
    </button>
    <button
      mat-stroked-button
      color="primary"
      id="view-documents"
      class="view-invoice-info"
      [disabled]="isReadOnly"
      (click)="onUpdateInvoiceInfo()">
      View Update Invoice Information
    </button>
  </div>
</div>
