<mat-sidenav-container autosize>
  <mat-sidenav [opened]="true" mode="side">
    <mat-nav-list>
      <a mat-list-item>
        <span class="entry">
          <mat-icon>house</mat-icon>
          <span>Dashboard</span>
        </span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
