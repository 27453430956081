<div class="notification-container">
  <div class="notificaiton-top">
    <div class="notification-top__title"><h2>Notifications</h2></div>

    <!-- Toggle Button -->
    <div class="toggle-group">
      <div class="button-container">
        <button (click)="setActive('GENERAL')" [ngClass]="{ active: activeButton === 'GENERAL' }">
          GENERAL NOTIFICATIONS
        </button>
        <button (click)="setActive('WAR_ROOM')" [ngClass]="{ active: activeButton === 'WAR_ROOM' }">WAR ROOM</button>
        <div
          class="active-indicator"
          [ngClass]="{ GENERAL: activeButton === 'GENERAL', WAR_ROOM: activeButton === 'WAR_ROOM' }"></div>
      </div>
    </div>

    <!-- Search Bar -->
    <div class="search-container">
      <div class="search-bar" [ngClass]="{ disabled: isSearchDisabled() }">
        <mat-icon class="search-icon">search</mat-icon>
        <input
          [disabled]="isSearchDisabled()"
          [ngClass]="{ disabled: isSearchDisabled() }"
          [readOnly]="isSearchDisabled()"
          [formControl]="searchControl"
          type="text"
          placeholder="Search Notifications" />
      </div>
      <ng-container *ngIf="activeButton === 'GENERAL'">
        <button mat-icon-button matSuffix [matMenuTriggerFor]="menu" class="filter-btn">
          <mat-icon fontSet="material-icons-outlined" class="filter-icon">filter_alt</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button (click)="setViewMode(this.channelTypes.HIGH_PRIORITY)" mat-menu-item>High Priority Incidents</button>
    <button (click)="setViewMode(this.channelTypes.JOB_RELATED)" mat-menu-item>Job Related Notifications</button>
    <button (click)="setViewMode(this.channelTypes.OTHER)" mat-menu-item>Other Notifications</button>
  </mat-menu>

  <!-- General Notifications -->

  <!-- Job Related Notifications -->
  <!-- General Notifications -->
  <ng-container *ngIf="activeButton === 'GENERAL' && viewMode === 'ALL'">
    <!-- No notifications -->
    <ng-container *ngIf="(totalNotifications$ | async) === 0">
      <div class="no-notifications-container">
        <img src="./assets/icons/notification-empty.svg" alt="no notifications icons" />
        <div class="no-notification-title">No Notifications Here</div>
        <div class="no-notification-body">
          There are no notifications to display at this time. Please check back later.
        </div>
      </div>
    </ng-container>
    <mat-list class="notification-section">
      <div class="category-section" *ngIf="(highPriorityNotifications$ | async)?.length > 0">
        <div class="group-header">
          <span>High Priority Notifications</span>
          <div (click)="setViewMode(this.channelTypes.HIGH_PRIORITY)" class="view-all-container" role="presentation">
            <span>View All</span>
            <div *ngIf="(highPriorityMessageCount$ | async) > 0">{{ highPriorityMessageCount$ | async }}</div>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </div>
        <div
          [ngClass]="{ unread: !notification.isRead }"
          class="notification-item"
          *ngFor="let notification of highPriorityNotifications$ | async">
          <mat-list-item (click)="clickHandler(notification)">
            <mat-icon class="high-priority" matListItemIcon>warning</mat-icon>
            <div matListItemTitle>
              {{ this.formatTemplate(notification.titleTemplate, notification.titleTemplateMetaData) }}
            </div>
            <div
              matListItemLine
              [matTooltip]="this.formatTemplate(notification.bodyTemplate, notification.bodyTemplateMetaData)"
              matTooltipPosition="above">
              {{ this.formatTemplate(notification.bodyTemplate, notification.bodyTemplateMetaData) }}
            </div>
            <div matListItemLine>{{ notification.createdAt | relativeTime }}</div>
          </mat-list-item>
          <mat-icon class="arrow-icon">chevron_right</mat-icon>
        </div>
      </div>

      <div class="category-section" *ngIf="(jobRelatedNotifications$ | async)?.length > 0">
        <div class="group-header">
          <span>Job Related Notifications</span>
          <div (click)="setViewMode(this.channelTypes.JOB_RELATED)" class="view-all-container" role="presentation">
            <span>View All</span>
            <div *ngIf="(jobRelatedMessageCount$ | async) > 0">{{ jobRelatedMessageCount$ | async }}</div>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </div>
        <div
          [ngClass]="{ unread: !notification.isRead }"
          class="notification-item"
          *ngFor="let notification of jobRelatedNotifications$ | async">
          <mat-list-item (click)="clickHandler(notification)">
            <mat-icon class="info" matListItemIcon>info</mat-icon>
            <div class="title" matListItemTitle>
              {{ this.formatTemplate(notification.titleTemplate, notification.titleTemplateMetaData) }}
            </div>
            <div
              matListItemLine
              [matTooltip]="this.formatTemplate(notification.bodyTemplate, notification.bodyTemplateMetaData)"
              matTooltipPosition="above">
              {{ this.formatTemplate(notification.bodyTemplate, notification.bodyTemplateMetaData) }}
            </div>
            <div matListItemLine>{{ notification.createdAt | relativeTime }}</div>
          </mat-list-item>
          <mat-icon class="arrow-icon">chevron_right</mat-icon>
        </div>
      </div>

      <!-- Other Notifications -->
      <div class="category-section" *ngIf="(otherNotifications$ | async)?.length > 0">
        <div class="group-header">
          <span>Other Notifications</span>
          <div (click)="setViewMode(this.channelTypes.OTHER)" class="view-all-container" role="presentation">
            <span>View All</span>
            <div *ngIf="(otherMessageCount$ | async) > 0">{{ otherMessageCount$ | async }}</div>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </div>
        <div
          [ngClass]="{ unread: !notification.isRead }"
          class="notification-item"
          *ngFor="let notification of otherNotifications$ | async">
          <mat-list-item (click)="clickHandler(notification)">
            <mat-icon class="notification-completed" matListItemIcon>check_circle</mat-icon>
            <div matListItemTitle>
              {{ this.formatTemplate(notification.titleTemplate, notification.titleTemplateMetaData) }}
            </div>
            <div
              matListItemLine
              [matTooltip]="this.formatTemplate(notification.bodyTemplate, notification.bodyTemplateMetaData)"
              matTooltipPosition="above">
              {{ this.formatTemplate(notification.bodyTemplate, notification.bodyTemplateMetaData) }}
            </div>
            <div matListItemLine>{{ notification.createdAt | relativeTime }}</div>
          </mat-list-item>
          <mat-icon class="arrow-icon">chevron_right</mat-icon>
        </div>
      </div>
    </mat-list>
  </ng-container>

  <!--notification-categories -->
  <!-- Specific Notification Categories -->
  <ng-container *ngIf="activeButton === 'GENERAL' && viewMode !== 'ALL'">
    <div class="view-all-header">{{ viewMode | statusToLower: ' ' }} Notifications</div>
    <mat-list class="notification-section">
      <div
        [ngClass]="{ unread: !notification.isRead }"
        class="notification-item"
        *ngFor="let notification of currentViewNotifications$ | async">
        <mat-list-item (click)="clickHandler(notification)">
          <mat-icon [ngClass]="getColorClass(viewMode)" matListItemIcon>{{ currentViewModeConfig.icon }}</mat-icon>
          <div matListItemTitle>
            {{ this.formatTemplate(notification.titleTemplate, notification.titleTemplateMetaData) }}
          </div>
          <div
            matListItemLine
            [matTooltip]="this.formatTemplate(notification.bodyTemplate, notification.bodyTemplateMetaData)"
            matTooltipPosition="above">
            {{ this.formatTemplate(notification.bodyTemplate, notification.bodyTemplateMetaData) }}
          </div>
          <div matListItemLine>{{ notification.createdAt | relativeTime }}</div>
        </mat-list-item>
        <mat-icon class="arrow-icon">chevron_right</mat-icon>
      </div>
    </mat-list>
    <div class="pagination-container">
      <hmt-paginator
        [whiteArrows]="false"
        [totalPages]="getTotalPages()"
        [currentPage]="currentPage"
        (pageChange)="onPageChange($event)"></hmt-paginator>
    </div>
  </ng-container>

  <!-- war room notifications -->

  <ng-container *ngIf="activeButton === 'WAR_ROOM'">
    <!-- no notifications -->
    <ng-container *ngIf="true">
      <div class="no-notifications-container">
        <img src="./assets/icons/notification-empty.svg" alt="no notifications icons" />
        <div class="no-notification-title">No Notifications Here</div>
        <div class="no-notification-body">
          There are no notifications to display at this time. Please check back later.
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
