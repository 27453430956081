// Routing values
export const APP_ROUTING = {
  main: '',
  taskFeed: {
    main: '/task-feed',
  },
  dashboard: {
    main: '/dashboard',
  },
  jobGroup: {
    main: '/job-group',
  },
  wizard: {
    main: '/wizard',
    jobFile: '/wizard/job-file',
  },
  // TODO: add other modules routes here
  auth: {
    main: '/auth',
    login: '/auth/login',
  },
  schedule: {
    main: '/schedule',
  },
  invoice: {
    main: '/invoice',
  },
  rateCardManager: {
    main: '/rate-card-manager',
  },
  runSheet: {
    main: '/run-sheet',
  },
  apiManagement: {
    main: '/api-management',
  },
  reports: {
    main: '/reports',
    jobMater: '/reports/job-master',
    downloads: '/reports/download',
  },
  organizationsManager: {
    main: '/organizations-manager/organizations/general-details',
  },
  aggregatedJPM: {
    main: '/aggregated-jpm',
    map: '/aggregated-jpm/map',
  },
  contracts: {
    main: '/contract',
  },
  fuel: {
    main: '/fuel',
  },
  quotation: {
    main: '/quotation',
  },
  controlTower: {
    main: '/control-tower',
  },
  orderManagement: {
    main: '/order-management',
  },
  contractManagement: {
    main: '/contract-management',
  },
};
