import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EnhancedDatagridNumberEditorParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'hmt-enhanced-datagrid-number-editor',
  templateUrl: './enhanced-datagrid-number-editor.component.html',
  styleUrls: ['./enhanced-datagrid-number-editor.component.scss'],
})
export class EnhancedDatagridNumberEditorComponent<TData = Record<string, unknown>> implements ICellEditorAngularComp {
  params!: EnhancedDatagridNumberEditorParams<TData>;
  initialText!: string;
  public textControl = new FormControl('');

  agInit(params: EnhancedDatagridNumberEditorParams<TData>): void {
    this.params = params;
    this.initialText = params.value;
    this.textControl.setValue(params.value);
  }

  getValue(): string {
    return this.textControl.value || '';
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Enter' && event.key !== 'Escape') {
      return;
    }
    if (event.key === 'Escape') {
      this.textControl.setValue(this.initialText);
    }
    this.params.stopEditing();
  }
}
