import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import { tap } from 'rxjs/operators';
import { ChannelGroupedNotificationsResult } from '../dto/channel-grouped-notifications.dto';
import { NotificationsByChannelResult } from '../dto/notifications-by-channel.dto';
import { UnreadNotificationsCountDto } from '../dto/unread-notification-count.dto';
import { AppNotification } from '../models/app-notification.model';
import {
  GET_CHANNEL_GROUPED_NOTIFICATIONS,
  GET_NOTIFICATIONS_BY_CHANNEL,
  GET_USER_NOTIFICATION_COUNT,
  MARK_NOTIFICATION_AS_READ,
} from '../queries/notification-gql-queries';
import {
  FetchChannelGroupedNotifications,
  FetchNotificationCount,
  FetchNotificationsByChannel,
  MarkNotificationAsRead,
  ResetNotificationState,
  ToggleNotificationDrawer,
} from './notification.actions';

export interface NotificationStateModel {
  highPriority: { items: AppNotification[]; total: number };
  jobRelated: { items: AppNotification[]; total: number };
  other: { items: AppNotification[]; total: number };
  warRoom: { items: AppNotification[]; total: number };
  currentView: { items: AppNotification[]; total: number };
  isNotificationDrawerOpen: boolean;
  unreadNotificationCount: {
    total: number;
    highPriority: number;
    jobRelated: number;
    other: number;
  };
}

@State<NotificationStateModel>({
  name: 'notifications',
  defaults: {
    highPriority: { items: [], total: 0 },
    jobRelated: { items: [], total: 0 },
    other: { items: [], total: 0 },
    warRoom: { items: [], total: 0 },
    currentView: { items: [], total: 0 },
    isNotificationDrawerOpen: false,
    unreadNotificationCount: {
      total: 0,
      highPriority: 0,
      jobRelated: 0,
      other: 0,
    },
  },
})
@Injectable()
export class NotificationState {
  constructor(
    private readonly apollo: Apollo,
    private readonly store: Store
  ) {}

  @Selector()
  static highPriorityNotificationsList(state: NotificationStateModel): AppNotification[] {
    return state.highPriority.items;
  }

  @Selector()
  static jobRelatedNotificationsList(state: NotificationStateModel): AppNotification[] {
    return state.jobRelated.items;
  }

  @Selector()
  static otherNotificationsList(state: NotificationStateModel): AppNotification[] {
    return state.other.items;
  }

  @Selector()
  static warRoomNotificationsList(state: NotificationStateModel): AppNotification[] {
    return state.warRoom.items;
  }

  @Selector()
  static currentViewNotificationsList(state: NotificationStateModel): AppNotification[] {
    return state.currentView.items;
  }

  @Selector()
  static totalNotifications(state: NotificationStateModel): number {
    return state.highPriority.total + state.jobRelated.total + state.other.total + state.warRoom.total;
  }

  @Selector()
  static warRoomTotal(state: NotificationStateModel): number {
    return state.warRoom.total;
  }

  @Selector()
  static currentViewTotal(state: NotificationStateModel): number {
    return state.currentView.total;
  }

  @Selector()
  static unreadNotificationsCount(state: NotificationStateModel): number {
    return state.unreadNotificationCount.total;
  }

  @Selector()
  static highPriorityUnreadNotificationsCount(state: NotificationStateModel): number {
    return state.unreadNotificationCount.highPriority;
  }

  @Selector()
  static jobRelatedUnreadNotificationsCount(state: NotificationStateModel): number {
    return state.unreadNotificationCount.jobRelated;
  }

  @Selector()
  static otherUnreadNotificationsCount(state: NotificationStateModel): number {
    return state.unreadNotificationCount.other;
  }

  @Selector()
  static showNotificationDrawer(state: NotificationStateModel): boolean {
    return state.isNotificationDrawerOpen;
  }

  @Action(FetchNotificationsByChannel)
  fetchNotificationsByChannel(
    { patchState }: StateContext<NotificationStateModel>,
    { userId, channel, offset, limit, searchTerm }: FetchNotificationsByChannel
  ) {
    return this.apollo
      .query<NotificationsByChannelResult>({
        query: GET_NOTIFICATIONS_BY_CHANNEL,
        variables: { userId, channel, offset, limit, searchText: searchTerm },
      })
      .pipe(
        tap(result => {
          patchState({
            currentView: {
              items: result.data?.getNotificationsByChannel.items,
              total: result.data?.getNotificationsByChannel.total,
            },
          });
        })
      );
  }

  @Action(FetchChannelGroupedNotifications)
  fetchChannelGroupedNotifications(
    { patchState }: StateContext<NotificationStateModel>,
    { userId, offset, limitPerChannel }: FetchChannelGroupedNotifications
  ) {
    return this.apollo
      .query<ChannelGroupedNotificationsResult>({
        query: GET_CHANNEL_GROUPED_NOTIFICATIONS,
        variables: { userId, limitPerChannel, offset },
      })
      .pipe(
        tap(result => {
          patchState({
            highPriority: {
              items: result.data?.getChannelGroupedNotifications.HIGH_PRIORITY.items,
              total: result.data?.getChannelGroupedNotifications.HIGH_PRIORITY.total,
            },
            jobRelated: {
              items: result.data?.getChannelGroupedNotifications.JOB_RELATED.items,
              total: result.data?.getChannelGroupedNotifications.JOB_RELATED.total,
            },
            other: {
              items: result.data?.getChannelGroupedNotifications.OTHER.items,
              total: result.data?.getChannelGroupedNotifications.OTHER.total,
            },
          });
        })
      );
  }

  @Action(MarkNotificationAsRead)
  markNotificationAsRead(_ctx: StateContext<NotificationStateModel>, { notificationId }: MarkNotificationAsRead) {
    // TODO: Remove dependency for user id
    const {
      user: { orgUserId: userId },
    } = this.store.selectSnapshot(s => s.auth);

    return this.apollo
      .query({
        query: MARK_NOTIFICATION_AS_READ,
        variables: { userId, notificationId },
      })
      .pipe(
        tap((result: any) => {
          console.log(result);
        })
      );
  }

  @Action(FetchNotificationCount)
  fetchNotificationCount({ patchState }: StateContext<NotificationStateModel>) {
    const {
      user: { orgUserId: userId },
    } = this.store.selectSnapshot(s => s.auth);

    return this.apollo
      .query<UnreadNotificationsCountDto>({
        query: GET_USER_NOTIFICATION_COUNT,
        variables: { userId },
      })
      .pipe(
        tap(result => {
          patchState({
            unreadNotificationCount: {
              total: result.data?.getUnreadNotificationCount.total,
              highPriority: result.data?.getUnreadNotificationCount.highPriority,
              jobRelated: result.data?.getUnreadNotificationCount.jobRelated,
              other: result.data?.getUnreadNotificationCount.other,
            },
          });
        })
      );
  }

  @Action(ToggleNotificationDrawer)
  toggleNotificationDrawer({ patchState, getState }: StateContext<NotificationStateModel>) {
    patchState({
      isNotificationDrawerOpen: !getState().isNotificationDrawerOpen,
    });
  }

  @Action(ResetNotificationState)
  resetNotificationState({ patchState }: StateContext<NotificationStateModel>) {
    patchState({
      highPriority: { items: [], total: 0 },
      jobRelated: { items: [], total: 0 },
      other: { items: [], total: 0 },
      warRoom: { items: [], total: 0 },
      currentView: { items: [], total: 0 },
      isNotificationDrawerOpen: false,
    });
  }
}
