import { LocationCountryDetails } from '@shared/models/org-country-details.model';
import { gql } from 'apollo-angular';

export const GET_ALL_COUNTRIES_AND_STATES_QUERY = gql`
  query GetAllCountries {
    getAllCountries {
      _id
      countryCode
      countryName
      createdBy
      currency
      gmtOffset
      states
      timezone
      updatedBy
      currencies {
        _id
        code
        createdBy
        currency
        shortCode
        updatedBy
      }
    }
  }
`;

export type GetAllCountriesAndStatesDTO = {
  getAllCountries: LocationCountryDetails[];
};
