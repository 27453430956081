import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API } from '@configs/api.config';
import { LocationType } from '@shared/enums/location-type.enum';
import { GatewayLocation } from '@shared/models/gateway-location.model';
import {
  GET_ALL_COUNTRIES_AND_STATES_QUERY,
  GetAllCountriesAndStatesDTO,
} from '@shared/queries/get-all-countries-and-states.query';
import { Apollo } from 'apollo-angular';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  private httpClient = inject(HttpClient);
  private gqlService = inject(Apollo);

  getLocationsByLocationIdAnType(
    locations: Array<{ locationId: string; locationType: LocationType }>
  ): Observable<GatewayLocation[]> {
    const url = API.gateway.getLocations;
    return this.httpClient.post<GatewayLocation[]>(url, locations);
  }

  getLocationsByType(locationType: LocationType): Observable<GatewayLocation[]> {
    const url = API.gateway.getLocationsByType.format([locationType]);
    return this.httpClient.get<GatewayLocation[]>(url);
  }

  getCountriesAndStates() {
    return this.gqlService.query<GetAllCountriesAndStatesDTO>({ query: GET_ALL_COUNTRIES_AND_STATES_QUERY }).pipe(
      take(1),
      map(response => response.data.getAllCountries)
    );
  }
}
