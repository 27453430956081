import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[hmtPositiveNumberInput]',
})
export class PositiveNumberInputDirective {
  @Input() decimalPlaces: number = 0;

  private readonly BACKSPACE_KEY = 8;
  private readonly TAB_KEY = 9;
  private readonly ENTER_KEY = 13;
  private readonly ESCAPE_KEY = 27;
  private readonly DELETE_KEY = 46;
  private readonly HOME_KEY = 35;
  private readonly END_KEY = 39;
  private readonly DECIMAL_POINT_KEY = 190;
  private readonly DIGIT_START = 48;
  private readonly DIGIT_END = 57;
  private readonly NUMPAD_START = 96;
  private readonly NUMPAD_END = 105;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const regex = this.decimalPlaces > 0 ? new RegExp(`^\\d*\\.?\\d{0,${this.decimalPlaces}}$`) : /^\d*$/;

    if (!regex.test(inputValue)) {
      inputElement.value = inputValue.replace(/[^\d.]/g, '');
      if (this.decimalPlaces > 0) {
        const parts = inputElement.value.split('.');
        if (parts.length > 1) {
          parts[1] = parts[1].slice(0, this.decimalPlaces);
          inputElement.value = parts.join('.');
        }
      }
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [this.BACKSPACE_KEY, this.TAB_KEY, this.ENTER_KEY, this.ESCAPE_KEY, this.DELETE_KEY];

    if (
      allowedKeys.includes(event.keyCode) ||
      // Allow: Ctrl+A
      (event.key === 'a' && event.ctrlKey) ||
      // Allow: Ctrl+C
      (event.key === 'c' && event.ctrlKey) ||
      // Allow: Ctrl+V
      (event.key === 'v' && event.ctrlKey) ||
      // Allow: Ctrl+X
      (event.key === 'x' && event.ctrlKey) ||
      // Allow: home, end, left, right
      (event.keyCode >= this.HOME_KEY && event.keyCode <= this.END_KEY)
    ) {
      return;
    }

    if (event.key === '-') {
      event.preventDefault();
      return;
    }

    // Ensure that it is a number or decimal point and stop the keypress
    if (
      (event.shiftKey || event.keyCode < this.DIGIT_START || event.keyCode > this.DIGIT_END) &&
      (event.keyCode < this.NUMPAD_START || event.keyCode > this.NUMPAD_END) &&
      (this.decimalPlaces === 0 || event.keyCode !== this.DECIMAL_POINT_KEY)
    ) {
      event.preventDefault();
    }
  }
}
