import { Directive, ElementRef, HostListener, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[hmtDefaultDecimalPlacesCommaDirective]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DefaultDecimalPlacesCommaDirective),
      multi: true,
    },
  ],
})
export class DefaultDecimalPlacesCommaDirective implements OnInit, ControlValueAccessor {
  @Input() decimalPlaces: number = 2;
  private hasValue: boolean = false;
  private previousValue: string = '';
  private onChange: (value: number) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const initialValue = this.el.nativeElement.value;
    if (initialValue) {
      this.hasValue = true;
      this.formatValue(initialValue, true);
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.hasValue = value.length > 0;
    this.formatValue(value, false);
    this.onChange(this.parseValue(value));
  }

  @HostListener('blur')
  onBlur() {
    if (this.hasValue) {
      this.formatValue(this.el.nativeElement.value, true);
    }
    this.onTouched();
  }

  private formatValue(value: string, addDecimalPlaces: boolean = true) {
    // Remove non-numeric characters except for the decimal point
    const numericValue = value.replace(/[^0-9.]/g, '');

    // Only allow one decimal point
    const parts = numericValue.split('.');
    if (parts.length > 2) {
      parts.pop();
    }

    // Format the integer part with commas
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Handle decimal places
    if (addDecimalPlaces && this.hasValue) {
      if (parts.length === 1) {
        parts.push('');
      }
      parts[1] = parts[1].padEnd(this.decimalPlaces, '0');
    }

    // Join the parts back together
    const formattedValue = parts.join('.');

    // Update the input value
    this.el.nativeElement.value = formattedValue;
    this.previousValue = formattedValue;
  }

  private parseValue(value: string): number {
    // Remove commas and convert to number
    return parseFloat(value.replace(/,/g, '')) || 0;
  }

  // ControlValueAccessor methods
  writeValue(value: number): void {
    this.el.nativeElement.value = value ? value.toFixed(this.decimalPlaces) : '';
    this.formatValue(this.el.nativeElement.value, true);
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.el.nativeElement.disabled = isDisabled;
  }
}
