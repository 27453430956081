import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttachmentModel } from '@core/models/attachment.model';
import { LoadType } from '@shared/enums/load-type.enum';
import { LocationType } from '@shared/enums/location-type.enum';
import { GatewayLocation } from '@shared/models/gateway-location.model';
import { GeoLocation } from '@shared/models/geo-location.model';
import { Location } from '@shared/models/location.model';
import { MultiSelectEntry } from '@shared/models/multi-select-entry.model';
import { Point } from '@shared/models/point.model';
import { Polygon } from '@shared/models/polygon.model';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { OrganizationLocation } from '../../modules/organizations-manager/models/organization-location.model';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private httpClient: HttpClient) {}

  getEnumKeyByEnumValue(relevantEnum, value): string {
    const keys = Object.keys(relevantEnum).filter(enumEntry => relevantEnum[enumEntry] === value);
    return keys.length > 0 ? keys[0] : null;
  }

  getMultiSelectEntryFromEnum(enumKey, relevantEnum): MultiSelectEntry {
    return Object.entries(relevantEnum)
      .map(status => ({ id: status[0], name: status[1] }))
      .find(item => item.id === enumKey) as MultiSelectEntry;
  }

  mapOrganizationLocationToGatewayLocationAsObservable(
    organizationLocation: Observable<OrganizationLocation>
  ): Observable<GatewayLocation> {
    return organizationLocation.pipe(
      mergeMap(item => {
        return of(this.mapOrganizationLocationToGatewayLocation(item));
      })
    );
  }

  mapOrganizationLocationToGatewayLocation(organizationLocation: OrganizationLocation): GatewayLocation {
    return {
      id: organizationLocation._id,
      name: organizationLocation?.locationName,
      locationType: organizationLocation?.locationType as LocationType,
      geoLocation: this.convertOrgLocGeoLocationToGateLocGeoLocation(organizationLocation.geolocation),
      geoFence: this.convertOrgLocGeoFenceToGateLocGeoFence(organizationLocation.geofence),
      principalContact: organizationLocation?.principalContact,
      address: organizationLocation?.address,
      phoneNumber: '',
      contact: {
        email: organizationLocation?.email,
        name: '',
        designation: '',
        mobile: '',
        phone: '',
      },
      faxNumber: '',
      bayInformation: [],
    };
  }

  convertOrgLocGeoLocationToGateLocGeoLocation(orgLocGeoLocation: Point): GeoLocation {
    if (
      !orgLocGeoLocation ||
      !orgLocGeoLocation.coordinates ||
      !Array.isArray(orgLocGeoLocation.coordinates) ||
      // TODO: REPLACE THIS MAGIC NUMBER WITH A NAMED CONSTANT
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      orgLocGeoLocation.coordinates.length < 2
    ) {
      return null;
    }

    return {
      lat: orgLocGeoLocation.coordinates[1],
      lng: orgLocGeoLocation.coordinates[0],
    };
  }

  convertOrgLocGeoFenceToGateLocGeoFence(orgLocGeoFence: Polygon): GeoLocation[] {
    if (!orgLocGeoFence || !orgLocGeoFence.coordinates) {
      return [];
    }
    return orgLocGeoFence.coordinates[0].map(item => {
      return {
        lat: item[1],
        lng: item[0],
      };
    });
  }

  mapOrganizationLocationToLocation(organizationLocation: OrganizationLocation): Location {
    return {
      id: organizationLocation?._id,
      name: organizationLocation?.locationName,
      type: organizationLocation?.locationType,
      address: organizationLocation?.address?.addressLine,
      phoneNumber: organizationLocation?.locationPhones[0]?.phoneNumber,
      contactPerson: organizationLocation?.principalContact?.firstName,
    };
  }

  getImageData(attachment: AttachmentModel): Observable<Blob> {
    return this.httpClient.get(attachment.url, { responseType: 'blob' }).pipe(map(res => new Blob([res])));
  }

  getImageDataByFileUrl(url: string): Observable<Blob> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(map(res => new Blob([res])));
  }

  getRelevantTextBasedOnKeyOfLoadType(loadType: LoadType) {
    switch (loadType) {
      case LoadType.CONTAINER:
        return 'Container';
      case LoadType.TRUCK:
        return 'Truck load';
      case LoadType.FCL:
        return 'Container';
      case LoadType.FTL:
        return 'Load';
      case LoadType.LCL:
        return 'Container';
      case LoadType.BOX:
        return 'Box';
      default:
        return 'Container';
    }
  }

  getRelevantTitleBasedOnKeyOfLoadType(loadType: LoadType) {
    switch (loadType) {
      case LoadType.CONTAINER:
        return 'Container';
      case LoadType.TRUCK:
        return 'Truck';
      case LoadType.FCL:
        return 'Container';
      case LoadType.FTL:
        return 'Load';
      case LoadType.LCL:
        return 'Load';
      case LoadType.BOX:
        return 'Box';
      default:
        return 'Load';
    }
  }
}
