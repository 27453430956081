import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DEBOUNCE_TIME, DEFAULT_OFFSET, NOTIFICATION_CONFIG } from '@configs/constants';
import { Select, Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged, Observable, Subject, takeUntil, tap } from 'rxjs';
import { NotificationComponent } from '../notification/notification.component';
import { SubscriptionChannels } from './enums/subscription-channels.enum';
import { AppNotification } from './models/app-notification.model';
import { FirebaseNotification } from './models/firebase-notification.model';
import { NotificationSnackBarConfigModel } from './models/snack-bar-config.model';
import { SnackBarNotificationModel } from './models/snack-bar.model';
import {
  FetchChannelGroupedNotifications,
  FetchNotificationCount,
  FetchNotificationsByChannel,
  MarkNotificationAsRead,
  ResetNotificationState,
} from './state/notification.actions';
import { NotificationState } from './state/notification.state';
import { CategoryBtnType } from './types/category-btn-type';

/*
 * TODO(Vansitha):
 * Move the worker initialization logic outside the drawer component.
 * Implement a service to manage the worker
 * Do not expose the worker directly to the drawer component since this component does not need to know about the
 * implementation details of the worker. Use actions to communicate with the worker.
 */
@Component({
  selector: 'hmt-notification-drawer',
  templateUrl: './notification-drawer.component.html',
  styleUrl: './notification-drawer.component.scss',
})
export class NotificationDrawerComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private userId: string;
  private worker: Worker;

  readonly LIMIT_DEFAULT_VIEW: number = 2;
  readonly LIMIT_PER_PAGE: number = 6;
  readonly channelTypes = SubscriptionChannels;

  @Select(NotificationState.totalNotifications) totalNotifications$: Observable<number>;

  @Select(NotificationState.highPriorityNotificationsList) highPriorityNotifications$: Observable<AppNotification[]>;
  @Select(NotificationState.highPriorityUnreadNotificationsCount) highPriorityMessageCount$: Observable<number>;

  @Select(NotificationState.jobRelatedNotificationsList) jobRelatedNotifications$: Observable<AppNotification[]>;
  @Select(NotificationState.jobRelatedUnreadNotificationsCount) jobRelatedMessageCount$: Observable<number>;

  @Select(NotificationState.otherNotificationsList) otherNotifications$: Observable<AppNotification[]>;
  @Select(NotificationState.otherUnreadNotificationsCount) otherMessageCount$: Observable<number>;

  @Select(NotificationState.currentViewNotificationsList) currentViewNotifications$: Observable<AppNotification[]>;
  @Select(NotificationState.currentViewTotal) currentViewTotal$: Observable<number>;

  @Output() tabChanged: EventEmitter<string> = new EventEmitter<string>();

  activeButton: CategoryBtnType = 'GENERAL';
  viewMode: SubscriptionChannels = SubscriptionChannels.ALL;
  indicatorStyle = { left: '0' };
  currentPage: number = 1;
  currentViewModeConfig: NotificationSnackBarConfigModel;
  searchControl: FormControl = new FormControl('');

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly store: Store,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.listenToUserAuthToStartWorker();
    const {
      user: { orgUserId: userId },
    } = this.store.selectSnapshot(s => s.auth);
    this.userId = userId;

    this.listenToNotificationsInRealtime();
    this.listenToSearchInput();
    this.refreshNotifications();
  }

  setActive(button: CategoryBtnType) {
    this.activeButton = button;
    if (button === 'GENERAL') {
      this.setViewMode(SubscriptionChannels.ALL);
    }
    this.indicatorStyle.left = button === 'GENERAL' ? '0' : '50%';
  }

  setActiveButton(button: CategoryBtnType): void {
    this.resetView();
    this.activeButton = button;
  }

  setViewMode(channel: SubscriptionChannels) {
    this.resetView();
    this.viewMode = channel;
    this.currentViewModeConfig = this.getNotificationConfig(this.viewMode);
    if (this.viewMode === SubscriptionChannels.ALL) {
      this.store.dispatch(new FetchChannelGroupedNotifications(this.userId, this.LIMIT_DEFAULT_VIEW, DEFAULT_OFFSET));
    } else {
      this.store.dispatch(new FetchNotificationsByChannel(this.userId, channel, DEFAULT_OFFSET, this.LIMIT_PER_PAGE));
    }
  }

  getTotalPages(): number {
    return Math.ceil(this.store.selectSnapshot(NotificationState.currentViewTotal) / this.LIMIT_PER_PAGE);
  }

  getColorClass(viewMode: SubscriptionChannels): string {
    if (viewMode === SubscriptionChannels.HIGH_PRIORITY) {
      return 'high-priority';
    } else if (viewMode === SubscriptionChannels.JOB_RELATED) {
      return 'info';
    } else if (viewMode === SubscriptionChannels.WAR_ROOM) {
      return 'message';
    } else if (viewMode === SubscriptionChannels.OTHER) {
      return 'notification-completed';
    } else {
      return '';
    }
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    const searchStr = this.searchControl.value;
    this.store.dispatch(
      new FetchNotificationsByChannel(this.userId, this.viewMode, page - 1, this.LIMIT_PER_PAGE, searchStr)
    );
  }

  clickHandler(notification: AppNotification) {
    if (notification.url) {
      this.router.navigateByUrl(notification.url);
    }

    if (!notification.isRead) {
      this.store.dispatch(new MarkNotificationAsRead(notification._id));
      this.refreshNotifications();
    }
  }

  formatTemplate(template: string, metadata: Record<string, string>): string {
    if (!metadata) {
      return template;
    }

    return template.replace(/\{([^}]+)\}([.,;:!?])?/g, (match, key, punctuation) => {
      const value = metadata[key];
      if (value === undefined) return match;

      // TODO: change after release
      if (key === 'extendedDateTime') {
        return this.formatDate(value);
      }

      const formattedValue = value;
      return formattedValue + (punctuation || '');
    });
  }

  isSearchDisabled(): boolean {
    return this.activeButton === 'GENERAL' && this.viewMode === SubscriptionChannels.ALL;
  }

  private isValidTimestamp(timestamp: string): boolean {
    const parsedDate = Date.parse(timestamp);
    return !isNaN(parsedDate);
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString;
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  }

  private initWorker(): void {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('./firebase-notifications.worker.ts', import.meta.url), { type: 'module' });

      const {
        user: { orgId, orgUserId: userId },
      } = this.store.selectSnapshot(s => s.auth);
      this.worker.postMessage({ orgId, userId });
    } else {
      console.error('Web workers are not supported in this environment');
    }
  }

  private listenToNotificationsInRealtime(): void {
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        this.showNotification(data);
      };
    }
  }

  private showNotification(notification: FirebaseNotification) {
    try {
      this.snackBar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: this.preparePayloadForSnackBar(notification),
        horizontalPosition: 'end',
        panelClass: 'custom-container',
        verticalPosition: 'top',
      });
      this.refreshNotifications();
    } catch (error) {
      console.error('Error showing notification', error);
    }
  }

  private preparePayloadForSnackBar(notification: FirebaseNotification): SnackBarNotificationModel {
    const modifiedNotification = {
      title: this.formatTemplate(notification.titleTemplate, notification.titleTemplateMetaData),
      body: this.formatTemplate(notification.bodyTemplate, notification.bodyTemplateMetaData),
      type: this.getNotificationConfig(notification.receivedChannel),
      url: notification.url,
    } as SnackBarNotificationModel;
    return modifiedNotification;
  }

  private getNotificationConfig(channel: string): NotificationSnackBarConfigModel {
    if (channel === SubscriptionChannels.WAR_ROOM) {
      return NOTIFICATION_CONFIG.textMessage;
    } else if (channel === SubscriptionChannels.HIGH_PRIORITY) {
      return NOTIFICATION_CONFIG.warning;
    } else if (channel === SubscriptionChannels.JOB_RELATED) {
      return NOTIFICATION_CONFIG.info;
    } else if (channel === SubscriptionChannels.OTHER) {
      return NOTIFICATION_CONFIG.success;
    } else {
      return NOTIFICATION_CONFIG.info;
    }
  }

  private refreshNotifications(): void {
    this.store.dispatch(new FetchNotificationCount());

    if (this.viewMode === SubscriptionChannels.ALL) {
      this.resetView();
      this.store.dispatch(
        new FetchChannelGroupedNotifications(this.userId, this.LIMIT_DEFAULT_VIEW, this.currentPage - 1)
      );
    } else {
      this.store.dispatch(
        new FetchNotificationsByChannel(this.userId, this.viewMode, this.currentPage - 1, this.LIMIT_PER_PAGE)
      );
    }
  }

  private resetView() {
    this.currentPage = 1;
    this.searchControl.setValue('');
    this.viewMode = SubscriptionChannels.ALL;
  }

  private listenToSearchInput(): void {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(DEBOUNCE_TIME),
        distinctUntilChanged(),
        tap(searchTerm => {
          this.store.dispatch(
            new FetchNotificationsByChannel(
              this.userId,
              this.viewMode,
              this.currentPage - 1,
              this.LIMIT_PER_PAGE,
              searchTerm
            )
          );
        })
      )
      .subscribe();
  }

  private terminateWorker(): void {
    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
    }
  }

  /**
   * Listens to user authentication to start the worker and terminate worker when user logs out
   */
  private listenToUserAuthToStartWorker(): void {
    this.store
      .select(state => state.auth.token)
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        tap(token => {
          if (!token) {
            this.terminateWorker();
          } else {
            this.initWorker();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetNotificationState());
    this.destroy$.next();
    this.destroy$.complete();
  }
}
