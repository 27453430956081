// TODO: UNCOMMENT AND FIX THIS
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hmt-contact-numbers',
  templateUrl: './contact-numbers.component.html',
  styleUrls: ['./contact-numbers.component.scss'],
})
export class ContactNumbersComponent {
  formGroup: FormGroup;
  @Input('formGroup') set setFormGroup(formGroup: FormGroup) {
    this.formGroup = formGroup;
  }
  @Output() addContactNumber = new EventEmitter();
  @Output() addFaxNumber = new EventEmitter();
  @Output() removeContactNumber = new EventEmitter<string>();
  @Output() removeFaxNumber = new EventEmitter<string>();
  constructor() {}

  public addContactNumberFormGroup() {
    this.addContactNumber.emit();
  }

  public addFaxNumberFormGroup() {
    this.addFaxNumber.emit();
  }

  public removeContactNumberInFormGroup(i) {
    this.removeContactNumber.emit(i);
  }

  public removeFaxNumberInFormGroup(i) {
    this.removeFaxNumber.emit(i);
  }
}
