<div class="datetime-editor">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="datetime-editor__input">
    <mat-label>{{ params?.labelForInput ?? 'Choose a date & time' }}</mat-label>
    <input
      matInput
      [owlDateTime]="dt1"
      [owlDateTimeTrigger]="dt1"
      (dateTimeChange)="onDateChange($event.value)"
      [value]="selectedDate" />
    <div matSuffix style="display: flex" [owlDateTimeTrigger]="dt1">
      <mat-icon>calendar_month</mat-icon>
    </div>
    <owl-date-time #dt1></owl-date-time>
  </mat-form-field>
</div>
