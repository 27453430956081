<div class="principle-contacts-container">
  <div class="header-container">
    <div class="header">
      <p>PRINCIPLE CONTACT</p>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="form" formGroupName="principalContacts">
      <div class="left-column">
        <mat-form-field class="first-name">
          <label>
            <input
              type="text"
              matInput
              formControlName="firstName"
              placeholder="First Name*"
              class="first-name-value" />
          </label>
          <mat-error *ngIf="formGroup.get('principalContacts.firstName')?.hasError('required')">
            First Name is required.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="division">
          <label>
            <input type="text" matInput formControlName="division" placeholder="Division" class="division-value" />
          </label>
        </mat-form-field>
        <mat-form-field class="nic">
          <label>
            <input type="text" matInput formControlName="nic" placeholder="NIC" class="nic-value" />
          </label>
          <mat-error *ngIf="formGroup.get('principalContacts.nic')?.hasError('pattern')"> NIC is invalid. </mat-error>
        </mat-form-field>
        <mat-form-field class="email">
          <label>
            <input type="text" matInput formControlName="email" placeholder="Email" class="email-value" />
          </label>
        </mat-form-field>
        <mat-form-field class="faxNumber">
          <label>
            <input type="text" matInput formControlName="faxNumber" placeholder="Fax Number" class="faxNumber-value" />
          </label>
        </mat-form-field>
      </div>
      <div class="mid-flex-grow"></div>
      <div class="right-column">
        <mat-form-field class="last-name">
          <label>
            <input type="text" matInput formControlName="lastName" placeholder="Last Name*" class="last-name-value" />
          </label>
          <mat-error *ngIf="formGroup.get('principalContacts.lastName')?.hasError('required')">
            Last Name is required.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="designation">
          <label>
            <input
              type="text"
              matInput
              formControlName="designation"
              placeholder="Designation"
              class="designation-value" />
          </label>
        </mat-form-field>
        <mat-form-field class="mobile-number">
          <label>
            <input
              type="text"
              matInput
              formControlName="mobileContact"
              placeholder="Office Mobile Number"
              class="mobile-number-value" />
          </label>
        </mat-form-field>
        <div class="contact-office" formGroupName="officeContact">
          <mat-form-field class="value">
            <label>
              <input
                type="text"
                matInput
                formControlName="phoneNumber"
                placeholder="Office Phone Number"
                class="state-value" />
            </label>
          </mat-form-field>
          <mat-form-field class="ext">
            <label>
              <input type="text" matInput formControlName="ext" placeholder="Ext" class="state-value" />
            </label>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
