import { Component } from '@angular/core';
import { EnhancedDatagridDateEditorParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'hmt-enhanced-datagrid-date-editor',
  templateUrl: './enhanced-datagrid-date-editor.component.html',
  styleUrls: ['./enhanced-datagrid-date-editor.component.scss'],
})
export class EnhancedDatagridDateEditorComponent<TData = Record<string, unknown>> implements ICellEditorAngularComp {
  params!: EnhancedDatagridDateEditorParams<TData>;
  public selectedDate!: Date;

  agInit(params: EnhancedDatagridDateEditorParams<TData>): void {
    this.params = params;
    this.selectedDate = params.value;
  }

  getValue(): Date {
    return this.selectedDate;
  }

  onDateChange(date: Date): void {
    this.selectedDate = date;
    this.params.stopEditing();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Escape') {
      return;
    }
    this.params.stopEditing();
  }
}
