<div class="date-editor">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="date-editor__input">
    <mat-label>{{ params?.labelForInput ?? 'Choose a date' }}</mat-label>
    <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event.value)" [value]="selectedDate" />
    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
