<!-- uploaded-document.component.html -->
<div class="uploaded-document">
  <div class="uploaded-document-details">
    <div class="uploaded-document-left">
      <div class="uploaded-document-icon">
        <img ngSrc="../../../../assets/icons/file-icon.svg" alt="" height="36" width="36" />
      </div>
      <div class="text-details">
        <p class="uploaded-document-name">{{ file().fileName }}</p>
        <p class="uploaded-date">{{ file().uploadedDate }}</p>
        <p class="uploaded-user"><b>Uploaded by: </b> {{ file().uploadedByName }}</p>
      </div>
    </div>
    <div class="uploaded-document-right">
      <button mat-icon-button color="primary" (click)="onViewBtnClicked()">
        <mat-icon>visibility_outlined</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="onDownloadBtnClicked()">
        <mat-icon>download</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="onDeleteBtnClicked()">
        <mat-icon>delete_outline</mat-icon>
      </button>
    </div>
  </div>
  <p *ngIf="file().isActive" class="alert-message">
    Alert! This is a red long alert about something that is very important and you should take action on it.
  </p>
</div>
